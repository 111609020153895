/* Styles for cookie consent banner */

/* #consent-manager
  div
    div.css-10z1g2q
      div (flex, flex wrap, justify between, items baseline, mt -16px)
        div.css-ra9y5f
          p.css-bhz559
            span
              a
        div.css-1wn42nw
          button.css-1k76mzs
          button.css-a0j149
          button.css-a0j149 */

#consent-manager {
  @apply !bg-brand-orange !border-brand-orange;

  & > div {
    @apply !border-brand-orange;

    /* .css-10z1g2q */
    & > div {
      @apply !bg-brand-orange !border-brand-orange;

      & > div {
        display: grid !important;
        align-items: unset !important;
        grid-template-rows: 1fr 1fr;
        margin-top: 0;

        & > div:first-child {
          grid-row: 1;
        }

        & > div:last-child {
          grid-row: 2;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: min-content;
          gap: .5rem;
          justify-content: center;

          & button:first-child {
            grid-column: 1;
          }

          & button:nth-of-type(2) {
            grid-column: 2;
          }

          & button:last-child {
            grid-column: 1 / -1;
          }
        }
      }
    }
  }

  & a {
    @apply text-brand-purple underline hover:underline visited:text-brand-purple;
  }

  & p {
    @apply paragraph-3 md:paragraph-4;

    & span {
      word-wrap: break-word;
    }
  }

  & button {
    @apply btn btn--tertiary paragraph-3 md:paragraph-4 w-full;
  }
}

@media (min-width: 768px) {
  #consent-manager {
    & > div {
      & > div {
        & > div {
          grid-template-rows: 1fr;
          grid-template-columns: 60% 40%;
          align-items: center !important;

          & > div:first-child {
            grid-row: 1;
            grid-column: 1;
          }

          & > div:last-child {
            grid-row: 1;
            grid-column: 2;
            display: flex;
            justify-content: flex-end;

            & button {
              @apply w-fit px-1;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  #consent-manager {
    & > div {
      & > div {
        & div {
          grid-template-columns: 70% 30%;
        }
      }
    }
  }
}

/* Styles for consent manager settings dialog window */

/* div[data-consent-manager-dialog]
  div.css-ueuzok
    section.css-zikcg4
      div.css-1353ifz
        h2.css-1er9f2w
        button.css-et0a3j
          svg
      form.css-1nh1424
        div.css-1s4cc41
          div.css-18uvdg2
            span
              a
          div.css-1xa4cku
            table.css-7xc4dj
              thead
                tr.css-1va7l79
                  th x3
              tbody
                tr x4
        div.css-rits9q
          div 
            div
              button
              button */

div[data-consent-manager-dialog] {
  & section {
    @apply text-brand-black paragraph-3 md:paragraph-4 bg-brand-white rounded-button;
    
    & > div {
      & h2 {
        @apply font-heading font-semibold italic uppercase;
      }
    }
  }

  & form {
    & div:first-child {
      & > div {
        & span {
          @apply paragraph-4;
        }

        & a {
          @apply text-brand-purple underline hover:underline visited:text-brand-purple;
        }
      }

      & table {
        table-layout: unset;

        & th {
          @apply !border-b-brand-black !border-brand-black;
        }

        & tr td label {
          gap: .25rem;
        }

        & input[type=radio] {
          display: unset;

          &:checked {
            @apply bg-brand-purple;
          }
        }
      }
    }
    & div:last-child {
      & button {
        @apply btn btn--secondary paragraph-3 md:paragraph-4 px-2;
      }
      
      /* The below two lines handle the button wrapper - they're nested differently in different windows within the same structure */
      & > div {
        @apply flex justify-end gap-2;
        & > div {
          @apply flex justify-end gap-2;
        }
      }
    }
  }
}

/* Styles for the consent banner that shows after updating preferences */
#consent-manager-update-banner {
  @apply !bg-brand-black !border-brand-black;

  & a {
    @apply underline hover:underline cursor-pointer mx-auto;
  }
}