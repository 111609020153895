 /**
 * Partstream Styling and fixes
 * Provided by one of the PartStream devs
 */

/* Fix Scroll */
#ariPartStream::before { 
  content: "";
  display: block;
  height: 120px;
}

#ariPartStream {
  padding-inline: 1rem !important;
}

/* Fix Zoom Icon displacement */
img.ari-PartSearchBtn, 
img.ari-ModelSearchBtn {
	display: inline !important;
}

/* Part Qty fix padding */
#ariPartStream input[id^='ariparts_qty'] {
	padding: 0.8rem 0 !important;
	border-radius: 5px !important;
}

/* Fix assembly height */
.ari-product-line.ari-assembly-select .item p{
  height:55px !important;
  padding: 5px !important;
}

/* Add to cart pop-up */
#ps-add{
	background: #ffffff;
	padding: 20px;
	position: fixed;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 30vmax;
	border: 1px solid #aaaaaa;
	box-shadow: 0 0 5px rgba(0,0,0,.2);
	z-index: 9999;
}

#ps-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgba(0,0,0,0.6);
	z-index: 10;
}

.ps-button {
	display: inline !important;
	margin: 20px 20px 0 0 !important;
}

/* Add to cart button */
input.ariPartListAddToCart {
  color: white !important;
  background: black !important;
  padding: 13px !important;
  font-weight: 600 !important;
  transition: 0.4s;
}

input.ariPartListAddToCart:hover {
  background: #9d9d9d !important;
}

#ariPartStream p {
  margin-bottom: auto !important;
}
  
#ariPartStream ul {
  list-style-type: none !important;
}
  
#ariPartList ul {
  margin: auto !important;
}

#ariPartList li {
  margin-top: 20px;
  padding-top: 15px;
}

@media only screen and (max-width: 900px) {
  #ariPartList li {
    padding-top: 4.5em;
  }
}