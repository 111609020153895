@layer base {
  .wrapper {
    @apply px-wrapper-mobile md:px-wrapper-sm-tablet lg:px-wrapper-tablet xl:px-wrapper-xs-desktop 2xl:px-wrapper-sm-desktop 3xl:px-wrapper-desktop max-w-wrapper mx-auto;
  }

  .wrapper-start {
    @apply pl-wrapper-mobile md:pl-wrapper-sm-tablet lg:pl-wrapper-tablet xl:pl-wrapper-xs-desktop 2xl:pl-wrapper-sm-desktop 3xl:pl-wrapper-desktop;
  }

  .wrapper-end {
    @apply pr-wrapper-mobile md:pr-wrapper-sm-tablet lg:pr-wrapper-tablet xl:pr-wrapper-xs-desktop 2xl:pr-wrapper-sm-desktop 3xl:pr-wrapper-desktop;
  }

  .site-grid {
    @apply grid grid-cols-mobile gap-mobile md:grid-cols-tablet md:gap-tablet xl:grid-cols-desktop xl:gap-sm-desktop 2xl:gap-desktop;
  }

  .flow > * + * {
    @apply mb-fluid-m;
  }
}

/* @link https://utopia.fyi/space/calculator?c=500,16,1.2,1240,17,1.333,5,4,&s=0.75|0.5|0.27,1.5|2|3|4|5|6|7|8,s-l&g=s,l,xl,12 */

:root {
/* Space 3xs: 4px → 5px */
--space-3xs: clamp(0.25rem, calc(0.23rem + 0.05vw), 0.31rem);

/* Space 2xs: 8px → 9px */
--space-2xs: clamp(0.50rem, calc(0.48rem + 0.05vw), 0.56rem);

/* Space xs: 12px → 13px */
--space-xs: clamp(0.75rem, calc(0.73rem + 0.05vw), 0.81rem);

/* Space s: 16px → 17px */
--space-s: clamp(1.00rem, calc(0.98rem + 0.05vw), 1.06rem);

/* Space m: 24px → 26px */
--space-m: clamp(1.50rem, calc(1.47rem + 0.10vw), 1.63rem);

/* Space l: 32px → 34px */
--space-l: clamp(2.00rem, calc(1.97rem + 0.10vw), 2.13rem);

/* Space xl: 48px → 51px */
--space-xl: clamp(3.00rem, calc(2.95rem + 0.15vw), 3.19rem);

/* Space 2xl: 64px → 68px */
--space-2xl: clamp(4.00rem, calc(3.94rem + 0.19vw), 4.25rem);

/* Space 3xl: 80px → 85px */
--space-3xl: clamp(5.00rem, calc(4.92rem + 0.24vw), 5.31rem);

/* Space 4xl: 96px → 102px */
--space-4xl: clamp(6.00rem, calc(5.91rem + 0.29vw), 6.38rem);

/* Space 5xl: 112px → 119px */
--space-5xl: clamp(7.00rem, calc(6.89rem + 0.34vw), 7.44rem);

/* Space 6xl: 128px → 136px */
--space-6xl: clamp(8.00rem, calc(7.88rem + 0.39vw), 8.50rem);

/* One-up pairs */
/* Space 3xs-2xs: 4px → 9px */
--space-3xs-2xs: clamp(0.25rem, calc(0.17rem + 0.24vw), 0.56rem);

/* Space 2xs-xs: 8px → 13px */
--space-2xs-xs: clamp(0.50rem, calc(0.42rem + 0.24vw), 0.81rem);

/* Space xs-s: 12px → 17px */
--space-xs-s: clamp(0.75rem, calc(0.67rem + 0.24vw), 1.06rem);

/* Space s-m: 16px → 26px */
--space-s-m: clamp(1.00rem, calc(0.85rem + 0.49vw), 1.63rem);

/* Space m-l: 24px → 34px */
--space-m-l: clamp(1.50rem, calc(1.35rem + 0.49vw), 2.13rem);

/* Space l-xl: 32px → 51px */
--space-l-xl: clamp(2.00rem, calc(1.71rem + 0.92vw), 3.19rem);

/* Space xl-2xl: 48px → 68px */
--space-xl-2xl: clamp(3.00rem, calc(2.70rem + 0.97vw), 4.25rem);

/* Space 2xl-3xl: 64px → 85px */
--space-2xl-3xl: clamp(4.00rem, calc(3.68rem + 1.02vw), 5.31rem);

/* Space 3xl-4xl: 80px → 102px */
--space-3xl-4xl: clamp(5.00rem, calc(4.67rem + 1.07vw), 6.38rem);

/* Space 4xl-5xl: 96px → 119px */
--space-4xl-5xl: clamp(6.00rem, calc(5.65rem + 1.12vw), 7.44rem);

/* Space 5xl-6xl: 112px → 136px */
--space-5xl-6xl: clamp(7.00rem, calc(6.64rem + 1.17vw), 8.50rem);

/* Custom pairs */
/* Space s-l: 16px → 34px */
--space-s-l: clamp(1.00rem, calc(0.73rem + 0.87vw), 2.13rem);
}
