@layer components {
  .category-carousel {
    @apply md:mx-auto md:wrapper box-border relative;	
  }
  
  .category-carousel .category-carousel__title {
    @apply sm:text-center pb-2 text-[1.75rem] uppercase font-heading font-semibold italic;
  }

  .category-carousel .category-carousel__container {
    @apply md:max-w-[86%] max-h-[410px] mx-auto overflow-hidden;
  }

  .category-carousel .category-carousel__container.category-carousel__full-width--md {
    @apply md:max-w-full;
  }

  .category-carousel .category-carousel__container.category-carousel__full-width--md .swiper-wrapper {
    @apply md:flex md:justify-center;
  }

  .category-carousel .category-carousel__container.category-carousel__full-width--lg {
    @apply md:max-w-[86%] lg:max-w-full;
  }

  .category-carousel .generic-card__title {
    @apply absolute heading-1 sm:heading-3 text-brand-white p-7 uppercase;
  }

  .category-carousel .generic-card__link-text {
    @apply absolute bottom-0 p-7 text-brand-white paragraph-2 font-heading font-semibold italic uppercase flex;
  }

  .category-carousel .generic-card__link-text svg {
    @apply w-7 h-7 block pl-2;
  }

  .category-carousel .generic-card__image-wrapper {
    @apply relative overflow-hidden max-h-[410px] sm:max-w-[410px];
  }

  .category-carousel .generic-card__image-wrapper img {
    @apply w-full h-[22.3rem] sm:h-[25.6rem] object-cover;
  }

  .category-carousel .category-carousel__button svg {
    @apply hidden text-brand-orange h-8 w-8 xl:h-12 xl:w-12 md:block;
  }
    
  .category-carousel .category-carousel__carousel .swiper-slide {
    @apply mr-2 md:mr-4;
  }

  .category-carousel .category-carousel__button-previous,
  .category-carousel .category-carousel__button-next {
    @apply absolute bottom-[55%];
  }

  .category-carousel .category-carousel__button-previous {
    @apply md:left-[38px] lg:left-[46px] xl:left-[59px] 2xl:left-[72px];
  }

  .category-carousel .category-carousel__button-next {
    @apply md:right-[38px] lg:right-[46px] xl:right-[59px] 2xl:right-[72px];
  }

  .category-carousel .category-carousel__scrollbar {
    @apply bg-brand-dark-grey block h-1 mt-1 max-w-[90%] md:max-w-[55%] mx-auto mt-12 md:mt-28;
  }

  .category-carousel .category-carousel__scrollbar .swiper-scrollbar-drag {
    @apply bg-brand-orange h-1;
  }

  .category-carousel .swiper-disabled {
    @apply flex overflow-x-auto;
  }

  .category-carousel .swiper-button-disabled svg {
    @apply text-brand-dark-grey;
  }

  .category-carousel .swiper-disabled .swiper-slide {
    @apply min-w-[80%] md:min-w-[30%];
  }

  .category-carousel .swiper-button-lock {
    @apply hidden;
  }
}
