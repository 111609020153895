@layer components {
  .adaptable-text-and-image {
    @apply py-fluid-l md:py-fluid-3xl;
  }

  .adaptable-text-and-image .adaptable-text-and-image__container {
    @apply sm:max-w-[1010px] overflow-hidden sm:mx-auto sm:justify-center sm:flex sm:flex-row sm:space-x-3;
  }

  .adaptable-text-and-image .adaptable-text-and-image__text-wrap,
  .adaptable-text-and-image .adaptable-text-and-image__image-wrap {
    @apply w-full h-auto max-h-[13.75rem] sm:max-w-[31rem] sm:max-h-[21.5rem];
  }

  .adaptable-text-and-image .adaptable-text-and-image__image-wrap {
    @apply mb-2 sm:mb-0 overflow-hidden;
  }

  .adaptable-text-and-image .adaptable-text-and-image__image-wrap img{
    @apply h-full w-full object-cover;
  }

  .adaptable-text-and-image .adaptable-text-and-image__text-wrap {
    @apply text-center pt-fluid-l px-fluid-m;
  }

  .adaptable-text-and-image .adaptable-text-and-image__text-wrap h2 {
    @apply paragraph-1 sm:paragraph-2 mb-fluid-xs;
  }

  .adaptable-text-and-image .adaptable-text-and-image__text-wrap p {
    @apply w-full sm:max-w-[18.5rem] mx-auto;
  }

  .adaptable-text-and-image .adaptable-text-and-image__scrollbar {
    @apply bg-brand-dark-grey block h-1 mt-fluid-l max-w-[90%] mx-auto;
  }

  .adaptable-text-and-image .adaptable-text-and-image__scrollbar .swiper-scrollbar-drag {
    @apply bg-brand-orange h-1;
  }

  .adaptable-text-and-image .swiper-scrollbar-lock {
    @apply hidden;
  }
}
