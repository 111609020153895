@layer components {
  .trust-signal {
    @apply overflow-x-hidden relative;
  }

  .trust-signal__container {
    @apply max-w-lg mb-fluid-m;
  }

  .trust-signal__content {
    @apply flex justify-center items-center;
  }

  .trust-signal__icon {
    @apply w-9 text-brand-orange bg-brand-orange-tint p-[7px] flex items-center rounded-full mr-fluid-s;
  }

  .trust-signal__content-wrapper {
    @apply md:flex;
  }

  .trust-signal__title,
  .trust-signal__subtitle {
    @apply paragraph-4 md:paragraph-3 text-brand-black font-normal; 
  }

  .trust-signal__title {
    @apply font-medium uppercase mr-fluid-s;
  }

  .trust-signal .swiper-pagination {
    @apply z-40 h-10 absolute w-full bottom-0 flex justify-center;
  }

  .trust-signal__bullets {
    @apply h-[3px] w-[33px] flex items-end bg-brand-dark-grey mr-[10px] hover:cursor-pointer;
  }

  .trust-signal__bullets.trust-signal__bullets-active {
    @apply bg-brand-orange;
  }
}