@layer components {
  .newsletter {
    @apply relative h-[27rem] sm:h-[21rem] overflow-hidden flex justify-end items-center;
  }

  .newsletter .newsletter__content-wrap {
    @apply wrapper w-full sm:w-1/2 sm:min-w-[30.5rem] sm:mr-0 h-full sm:h-auto py-14 sm:py-0;
  }

  .newsletter .newsletter__content {
    @apply h-full flex flex-col sm:block sm:h-auto max-w-[30.5rem] relative z-10 text-brand-white m-auto;
  }

  .newsletter .newsletter__heading {
    @apply w-[80%] sm:w-full paragraph-2 font-semibold italic uppercase pb-2;
  }

  .newsletter .newsletter__sub-heading {
    @apply mt-auto sm:mt-0 paragraph-3 pb-8 sm:pb-4;
  }

  .newsletter .newsletter__input-container {
    @apply relative bg-brand-white text-brand-black;
  }

  .newsletter .newsletter__input-container input{
    @apply !border-transparent focus:border-transparent focus:ring-0 !outline-0 w-4/5 fluid-text-sm !font-semibold;
  }

  .newsletter .newsletter__input-container .newsletter__submit{
    @apply w-1/5 h-11 top-2/4 -translate-y-2/4 underline absolute fluid-text-sm right-0 top-5;
  }

  .newsletter .newsletter__background-image {
    @apply absolute inset-0;
  }

  .mce_inline_error:not(.email) {
    @apply !bg-brand-red !fluid-text-sm !font-normal !m-0;
  }

  #mce-success-response {
    @apply py-2 px-4 !bg-brand-orange !text-brand-white !fluid-text-sm !font-normal !bg-brand-orange;
  }
}