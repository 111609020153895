@layer components {
  [data-cart-stock-level-status] {
    @apply after:content-[''] after:inline-block after:w-2 after:h-2 after:rounded-full after:ml-fluid-2xs after:bg-transparent;
  }

  [data-cart-stock-level-status="true"] {
    @apply  after:bg-brand-green;
  }

  [data-cart-stock-level-status="false"] {
    @apply after:bg-brand-purple;
  }
}