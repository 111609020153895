/**
 * Typography
 * Global typography styles and custom utility classes
 */

 @layer base {
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  /* All typography classes use the same font */
  :is(.heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .paragraph-1, .paragraph-2, .paragraph-3, .paragraph-4, .paragraph-5) {
    @apply font-heading;
  }

  /* All heading classes use semibold italic style */
  :is(.heading-1, .heading-2, .heading-3, .heading-4, .heading-5) {
    @apply font-semibold italic;
  }

  /* All paragraph classes use normal style */
  :is(.paragraph-1, .paragraph-2, .paragraph-3, .paragraph-4, .paragraph-5) {
    @apply font-normal;
  }

  /* Heading class font-size */
  .heading-1 { @apply fluid-text-4xl; }
  .heading-2 { @apply fluid-text-3xl; }
  .heading-3 { @apply fluid-text-2xl; }
  .heading-4 { @apply fluid-text-xl; }
  .heading-5 { @apply fluid-text-lg; }

  /* Paragraph class font-size */
  .paragraph-1 { @apply fluid-text-xl; }
  .paragraph-2 { @apply fluid-text-lg; }
  .paragraph-3 { @apply fluid-text-base; }
  .paragraph-4 { @apply fluid-text-sm; }
  .paragraph-5 { @apply fluid-text-xs; }
}

/* Apply typography styles to content using utility classes */
@layer components {
  /* All heading levels get .heading-3 */
  .prose :is(h1, h2, h3, h4) { @apply heading-3; }
  
  .prose :is(h1) { @apply mb-fluid-xl; }

  .prose :is(a) { @apply underline; }

  .prose :is(ul, ol) { @apply wrapper-start md:px-fluid-s; }

  .prose :is(ul, ol > li) { @apply list-disc; }
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  position: relative;

}