@layer components {
    .cart__line-items {
        li {
            @apply border-b-2 border-x-brand-dark-grey;
            &:last-child {
                @apply lg:border-none;
            }
        }

    }
    .basket-icon {
        @apply relative;

        &::before {
            @apply w-[1.25rem] h-[1.25rem] min-w-[1.25rem] min-h-[1.25rem] bg-brand-orange text-white font-bold text-center text-xs leading-5 rounded-full absolute top-0 right-0;
            content: attr(data-basket-item-count);
        }

        &[data-basket-item-count="0"]::before {
            content: none;
        }
        
    }
}