@layer components {
  footer .accordion__drawer:first-child {
    @apply border-t-0;
  }

  footer .footer__subnavigation-links li::after {
    @apply content-['|'] relative px-2;
  }

  footer .footer__subnavigation-links li:last-child::after {
    @apply hidden;
  }

  footer .accordion {
    @apply border-t-0;
  }

  footer .accordion__drawer {
    @apply hover:bg-transparent;
  }

  footer .accordion__drawer-button {
    @apply flex justify-between;
  }

  footer .accordion__drawer-button--icon {
    @apply block w-6 h-6;
  }

  footer .accordion__drawer[data-state="open"] .accordion__drawer-button--icon-add {
    @apply hidden;
  }

  footer .accordion__drawer[data-state="open"] .accordion__drawer-button--icon-remove {
    @apply block;
  }

  footer .accordion__drawer[data-state="closed"] .accordion__drawer-button--icon-add {
    @apply block;
  }

  footer .accordion__drawer[data-state="closed"] .accordion__drawer-button--icon-remove {
    @apply hidden;
  }


  footer .footer-payment-icons {
    @apply flex flex-wrap items-center;
  }

  footer .footer-payment-icons .footer-payment-icon {
    @apply h-[20px] w-[35px] mr-[10px] p-0.5 bg-brand-white rounded border border-[#707070];
  }

  footer .footer-payment-icons .footer-payment-icon svg {
    @apply w-full;
  }

}

footer .wrapper {
  background-image: url("../img/j.svg");
  background-position: bottom -51px right -50px;
  background-repeat: no-repeat;
  background-size: 300px;
}

@media screen and (min-width: 1024px) {
  footer .wrapper {
    background-position: bottom -125px right -100px;
    background-size: 730px;
  }
}

footer .accordion__drawer-button::after {
  background-image: none !important;
}

