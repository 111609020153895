@layer base {
  .btn {
    @apply paragraph-3 border-button rounded-button border-brand-black py-[16px] w-fit leading-[1] transition-colors duration-500 flex items-center justify-center gap-fluid-xs;
  }

  .btn--link {
    @apply paragraph-2 underline underline-offset-[0.5px] flex items-center gap-fluid-xs;
  }

  .btn .btn--icon {
    @apply block h-[16px] w-[16px];
  }

  .btn .btn--icon svg {
    @apply w-full h-full;
  }

  .btn--link .btn--icon {
    @apply block h-[16px] w-[16px];
  }

  .btn--link .btn--icon svg {
    @apply w-full h-full;
  }
}

@layer components {
  .btn--primary {
    @apply bg-brand-black text-brand-white hover:bg-brand-dark-grey hover:border-brand-dark-grey outline-0 ring-offset-2 focus:ring-2 ring-brand-orange focus:bg-brand-black focus:border-brand-black focus:text-brand-white disabled:bg-brand-light-grey disabled:text-brand-dark-grey disabled:border-brand-light-grey;
  }

  .btn--primary[disabled] {
    @apply bg-brand-dark-grey text-white;
  }

  .btn--secondary {
    @apply bg-brand-white text-brand-black hover:text-brand-black hover:bg-brand-dark-grey outline-0 ring-offset-2 focus:ring-2 ring-brand-orange focus:bg-brand-white focus:text-brand-black disabled:bg-brand-light-grey disabled:text-brand-dark-grey disabled:border-brand-light-grey;
  }

  .btn--tertiary {
    @apply text-brand-white hover:text-brand-black border-brand-white ring-offset-transparent hover:bg-brand-white outline-0 focus:bg-transparent focus:text-brand-white disabled:bg-brand-light-grey disabled:text-brand-dark-grey disabled:border-brand-light-grey;
  }

  .btn--display {
    @apply backdrop-blur-[30px] text-brand-white hover:text-brand-black border-brand-white focus:ring-offset-brand-black/[0.2] focus:text-brand-white hover:ring-offset-transparent hover:bg-brand-white outline-0 ring-offset-2 focus:ring-2 ring-brand-white  focus:bg-transparent disabled:bg-brand-light-grey disabled:text-brand-dark-grey disabled:border-brand-light-grey;
  }

  .btn--xsmall {
    @apply paragraph-4 px-fluid-s py-fluid-xs;
  }

  .btn--small {
    @apply px-fluid-m;
  }

  .btn--medium {
    @apply px-fluid-xl;
  }

  .btn--large {
    @apply px-fluid-3xl;
  }

  .btn--link-small {
    @apply paragraph-3;
  }
}
