@layer components {
  .breadcrumb:not(:last-child)::after {
    content: ">";
    display: inline-block;
  }

  .breadcrumbs {
    @apply md:paragraph-3;
  }

  .product-info .breadcrumbs{
    @apply md:paragraph-4 mb-fluid-s md:mb-fluid-m;
  }

  .breadcrumb .filter.downcase {
    @apply paragraph-4;
  }
}
