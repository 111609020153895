/* Styles for mobile menu */

@layer components {
    .mobile-menu [aria-hidden=true] {
        display: none;
    }

    .menu-back-arrow {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17px' height='17px' viewBox='8 8 32 32'%3E%3Cg clip-path='url(%23clip-Left_arrow)'%3E%3Cpath d='M23.167 11.459a1.561 1.561 0 0 1 0 2.209l-9.833 9.834h25.4a1.562 1.562 0 1 1 0 3.125H13.339l9.833 9.833a1.562 1.562 0 0 1-2.209 2.209L8.492 26.198a1.562 1.562 0 0 1 .008-2.274L20.964 11.46a1.562 1.562 0 0 1 2.209 0Z' fill='%23ef5722'/%3E%3C/g%3E%3C/svg%3E") left center no-repeat;
    }

    .menu-forward-arrow {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17px' height='17px' viewBox='10 10 32 32'%3E%3Cpath d='M25.979 38.604a1.561 1.561 0 0 1 0-2.209l9.833-9.834h-25.4a1.562 1.562 0 1 1 0-3.125h25.395l-9.833-9.833a1.562 1.562 0 0 1 2.209-2.209l12.471 12.471a1.562 1.562 0 0 1-.008 2.274L28.182 38.603a1.562 1.562 0 0 1-2.209 0Z' fill='%23ef5722'/%3E%3C/svg%3E") right center no-repeat;
    }
}