@layer component {
  .product-price {
    @apply flex paragraph-2 mb-fluid-s;
  }

  .product-price__sale-price {
    @apply text-brand-red;
  }

  .product-price__original-price {
    @apply -order-1 mr-fluid-s italic line-through;
  }
}