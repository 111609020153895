@layer components {
  .category-grid {
    @apply grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-mobile md:gap-tablet xl:gap-sm-desktop 2xl:gap-desktop col-start-1 xl:col-start-2 col-span-8 xl:col-span-10;
  }

  .category-grid-item a {
    @apply block w-full aspect-w-1 aspect-h-1 relative rounded-button bg-brand-black;
  }

  .category-grid .category-grid__image {
    @apply w-full h-full object-cover rounded-button opacity-70;
  }

  .category-grid .category-grid__content {
    @apply absolute h-full w-full text-brand-white p-fluid-2xs lg:p-fluid-s;
  }

  .category-grid .category-grid__title {
    @apply paragraph-1 font-semibold italic 2xl:heading-4 3xl:heading-3 z-40 uppercase;
  }
}
