#inv-oos{
    background-color: #FEEEE8;
    margin-bottom: 2rem;
    border-radius: 0.4rem;
    padding: 1rem;
}
#inv-text{
    padding-bottom: 1rem;
}

#inv-oos-input-submit{
    display: flex;
    align-items: baseline;
}

#inv-submit{
    background-color: black;
    border: 1px solid black;
    color: white;
    width: max-content;
    padding: 0.5rem 1rem;
    cursor: pointer;
}