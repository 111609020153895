@layer components {
    input[type=radio] {
      @apply hidden;
    }

    input[type=radio]:checked ~ .radio:before{
      content: '';
      background-image: url("../img/checkbox-checked.svg");
    }

    label:disabled {
      @apply text-brand-dark-grey;
    }

    input[type=radio]:disabled ~ .radio {
      filter: invert(63%) sepia(4%) saturate(12%) hue-rotate(356deg) brightness(100%) contrast(83%);
    }

    label {
      @apply flex items-center mb-3;
    }

    .radio {
      @apply flex items-center;
    }

    .radio:before {
      content: '';
      background-image: url("../img/checkbox.svg");
      @apply inline-block w-5 h-5 mr-[10px] text-green-600;
      min-height: 1.25rem;
      min-width: 1.25rem;
    }
  }