@layer components {
  input[type=checkbox] {
    @apply hidden;
  }

  input[type=checkbox]:checked ~ .checkbox:before{
    content: '';
    background-image: url("../img/checkbox-checked.svg");
  }

  button.checkbox[checked="true"]{
    content: '';
    background-image: url("../img/check-green.svg");
    background-repeat: no-repeat;
  }

  label:disabled {
    @apply text-brand-dark-grey;
  }

  input[type=checkbox]:disabled ~ .checkbox {
    filter: invert(63%) sepia(4%) saturate(12%) hue-rotate(356deg) brightness(100%) contrast(83%);
  }

  label {
    @apply flex items-center mb-3;
  }

  .checkbox {
    @apply flex items-center text-left;
  }

  .checkbox:before {
    content: '';
    background-image: url("../img/checkbox.svg");
    @apply inline-block w-5 h-5 mr-[10px] text-green-600;
    min-height: 1.25rem;
    min-width: 1.25rem;
  }

  .checkbox.checkbox-red:before {
    filter: invert(20%) sepia(90%) saturate(5493%) hue-rotate(354deg) brightness(98%) contrast(90%);
  }

  .checkbox.checkbox-white:before {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  }
}
