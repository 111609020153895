/* Styles for meganav */

@layer components {
  /* Set <section> as a container for container query */
  .mega-menu__section {
    container-type: size;
    container-name: megamenu;
  }

  @container megamenu (max-height: 600px) {
    .mega-menu__link--top-level {
      padding: var(--space-2xs) !important;
      line-height: 1.25rem;
    }
  }
}