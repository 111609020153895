@layer components {
  .accordion {
    @apply max-w-[645px] border-t border-brand-black;
  }

  .accordion__drawer {
    @apply border-b border-brand-black overflow-hidden hover:bg-brand-light-grey;
  }

  .accordion__drawer-button {
    @apply w-full flex relative items-center text-left;
  }

  .accordion__drawer-button span {
    @apply font-normal;
  }

  .accordion__drawer-header {
    @apply py-3 px-4 !mb-0;
  }

  .accordion__drawer-button::after {
    content: '';
    background-image: url("../img/circle-add.svg");
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    transition: all 0.2s;
  }

  .accordion__drawer-content {
    @apply max-w-[452px] ease-in-out duration-100 overflow-hidden;
  }

  .accordion__drawer-content .p-4 a {
    @apply text-brand-purple underline hover:underline visited:text-brand-purple;
  }

  /* !important used to override hard-coded style tag values */
  .accordion__drawer-content table {
    min-height: fit-content;
    height: 100% !important;
    min-width: fit-content;
    width: 100% !important;
  }

  .accordion__drawer[data-state="open"] .accordion__drawer-button::after,
  article[data-state="open"] .accordion__drawer-button::after {
    background-image: url("../img/circle-subtract.svg");
  }

  article[data-state="open"] {
    @apply pb-5; 
  }

  [data-tab="quick-search"][data-state="closed"] + #quick-search-button {
    display: none;
  }

  .accordion-filter-red {
    filter: brightness(0) saturate(100%) invert(17%) sepia(80%) saturate(5580%) hue-rotate(355deg) brightness(105%) contrast(87%);
  }

  .accordion-red {
    @apply border-0 max-w-[645px];
  }

  .accordion-red .accordion__drawer {
    @apply border-brand-red md:border-brand-black; 
  }

  .accordion-red .accordion__drawer-button::after {
    @apply accordion-filter-red md:filter-none;
  }

  .accordion-red-styleguide .accordion__drawer-button::after{
    @apply accordion-filter-red;
  }
  
  .accordion.accordion-red-styleguide {
    @apply border-0;
  }

  .accordion-red-styleguide .accordion__drawer {
    @apply border-brand-red; 
  }
}
