@layer components {
  /* Hide Google reCAPTCHA badge */
  .grecaptcha-badge {
    visibility: hidden;
  }

  /* Add styling for CAPTCHA challenge page */
  .shopify-challenge__container {
  }

  .shopify-challenge__message {
  }

  .shopify-challenge__button {
  }

  /* Add styling for Shopify payment buttons */
  .shopify-payment-button__button {
  }

  .shopify-payment-button__button--unbranded {
  }

  /* Add styling for Policy pages */
  .shopify-policy__container {
  }

  .shopify-policy__title {
  }

  .shopify-policy__body {
  }

}


