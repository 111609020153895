/**
 * styles.css Base styles for Tailwind Utility classes
 */

@import './components/layout.css';
@import './components/typography.css';
@import './components/buttons.css';
@import './components/checkboxes.css';
@import './components/radiobutton.css';
@import './components/overrides.css';
@import './components/drawer.css';
@import './components/spacing.css';
@import './components/input.css';
@import './components/swiper.css';
@import './components/accordion.css';
@import './components/breadcrumb.css';
@import './components/table.css';
@import './components/footer/footer.css';
@import './components/centrifuge.css';
@import './components/partstream.css';
@import './components/mobile-menu.css';
@import './components/megamenu.css';
@import './components/consent-manager.css';

 /**
* cart
*/

@import './components/cart/cart-line-items.css';
@import './components/cart/loading-spinner.css';

 /**
 * product
 */

 @import './components/product/gallery.css';
 @import './components/product/prices.css';
 @import './components/product/swatch.css';
 @import './components/product/stock-level.css';
 @import './components/product/rectangle-list.css';
 @import './components/product/trust-signals.css';
 @import './components/product/carousel.css';

 /**
 * Widgets
 */
 
 @import './components/widgets/logo-list.css';
 @import './components/widgets/category-grid.css';
 @import './components/widgets/text-and-image.css';
 @import './components/widgets/category-carousel.css';
 @import './components/widgets/text.css';
 @import './components/widgets/text-and-video.css';
 @import './components/widgets/hero-banner.css';
 @import './components/widgets/newsletter.css';
 @import './components/widgets/adaptable-text-and-image.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.announcement--hide .announcement-banner,
.announcement-banner[hidden] {
  display: none;
}

.page-overlay {
  background-color: black;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  position: fixed;
  right: 0;
  top: 0;
}

.submenu {
  transition: all 600ms cubic-bezier(.75,0,.175,1),visibility 600ms cubic-bezier(.75,0,.175,1);
}

.currency-dropdown-open {
  background: #2B2C30;
  padding-top: 20px;
  margin-bottom: .5rem;
}

[data-drop-content='hidden'], [data-mobile-drop-content='hidden'] {
  display: none;

}

[data-mobile-drop-toggle='open'] [mobile-data-drop-icon]  {
  transform: rotate(270deg);
}

.form-inlineMessage {
  @apply paragraph-4 text-brand-purple;
}

.margin-left .form-inlineMessage {
  @apply ml-[30px];
}

.alert-close-button svg {
    height: 1rem;
    width: 1rem;
}