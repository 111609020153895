.loading-spinner__container {
    display: flex;
    justify-content: center;
}

.loading-spinner {
    animation: spinner 0.6s linear infinite;
    background: inherit;
    border: 3px solid #EF5722;
    border-radius: 50%;
    border-right-color: #FEEEE8;
    display: inline-block;
    height: 1.2rem;
    margin-left: 0.5rem;
    width: 1.2rem;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
