@layer components {
    .product-carousel {
      @apply box-border relative;	
    }
    
    .product-carousel .product-carousel__title {
      @apply sm:text-center pb-2 text-[1.75rem] uppercase font-heading font-semibold italic;
    }
  
    .product-carousel .product-carousel__container {
      @apply mx-auto overflow-hidden;
    }

    .product-carousel .product-carousel__container-fullwidth {
      @apply md:max-w-[100%] mx-auto overflow-hidden;
    }
  
    .product-carousel .product-carousel__container.product-carousel__full-width--md {
      @apply md:max-w-full;
    }
  
    .product-carousel .product-carousel__container.product-carousel__full-width--md .swiper-wrapper {
      @apply md:flex md:justify-center;
    }
  
    .product-carousel .product-carousel__container.product-carousel__full-width--lg {
      @apply md:max-w-[86%] lg:max-w-full;
    }
  
    .product-carousel .generic-card__title {
      @apply absolute heading-1 sm:heading-3 text-brand-white p-7 uppercase;
    }
  
    .product-carousel .generic-card__link-text {
      @apply absolute bottom-0 p-7 text-brand-white paragraph-2 font-heading font-semibold italic uppercase flex;
    }
  
    .product-carousel .generic-card__link-text svg {
      @apply w-7 h-7 block pl-2;
    }
  
    .product-carousel .generic-card__image-wrapper {
      @apply relative overflow-hidden max-h-[410px] sm:max-w-[410px];
    }
  
    .product-carousel .generic-card__image-wrapper img {
      @apply w-full h-[22.3rem] sm:h-[25.6rem] object-cover;
    }
  
    .product-carousel .product-carousel__button svg {
      @apply hidden text-brand-orange h-8 w-8 xl:h-12 xl:w-12 md:block;
    }
      
    .product-carousel .product-carousel__carousel .swiper-slide {
      @apply mr-2 md:mr-4;
    }
  
    .product-carousel .product-carousel__button-previous,
    .product-carousel .product-carousel__button-next {
      @apply absolute bottom-[50%];
    }
  
    .product-carousel .product-carousel__button-previous {
      @apply md:left-[-40px] xl:left-[-60px];
    }

    .product-carousel .product-custom-carousel-previous {
      @apply left-0 md:left-[-45px] xl:left-[-60px];
    }
  
    .product-carousel .product-carousel__button-next {
      @apply md:right-[-40px] xl:right-[-70px];
    }

    .product-carousel .product-custom-carousel-next{
      @apply right-0 md:right-[-45px] xl:right-[-60px];
    }
  
    .product-carousel .product-carousel__scrollbar {
      @apply bg-brand-dark-grey block h-1  max-w-[90%] md:max-w-[55%] mx-auto mt-10;
    }
  
    .product-carousel .product-carousel__scrollbar .swiper-scrollbar-drag {
      @apply bg-brand-orange h-1;
    }
  
    .product-carousel .swiper-disabled {
      @apply flex overflow-x-auto;
    }
  
    .product-carousel .swiper-button-disabled svg {
      @apply text-brand-dark-grey;
    }
  
    .product-carousel .swiper-disabled .swiper-slide {
      @apply min-w-[80%] md:min-w-[30%];
    }
  
    .product-carousel .swiper-button-lock {
      @apply hidden;
    }
  }
  