@layer components {
  .logo-list {
    @apply m-auto;
  }

  .logo-list .logo-list__wrapper {
    @apply max-w-[171rem] m-auto flex pt-8 px-0 pb-0 sm:px-8 sm:pt-8 flex-wrap justify-center;
  }

  .logo-list .logo-list__image {
    @apply max-w-[50%] sm:max-w-[33%] px-8 pb-8; 
  }

  .logo-list .logo-list__image img {
    @apply object-contain w-auto h-[3.75rem];
  }
}