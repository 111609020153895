@layer components {
  .hero-banner {
    @apply aspect-w-4 aspect-h-5 md:aspect-w-5 md:aspect-h-2 bg-brand-orange;
  }

  .hero-banner__item {
    @apply relative h-full w-full;
  }

  .hero-banner__image {
    @apply absolute h-full w-full z-10 object-cover;
  }

  .hero-banner__content {
    @apply absolute h-full w-full z-20 py-fluid-3xl flex flex-col;
  }

  .hero-banner__subtitle {
    @apply paragraph-2 italic uppercase mb-fluid-3xs;
  }

  .hero-banner__title {
    @apply uppercase max-w-[40%] md:max-w-[20%] mb-fluid-xs-s heading-2;
  }

  .hero-banner__button {
    @apply btn btn--display btn--large;
  }

  .hero-banner__pagination {
    @apply z-40 h-10 absolute w-full bottom-fluid-xl;
  }

  .hero-banner-bullets {
    @apply h-[3px] w-[50px] md:w-[120px] flex items-end bg-brand-white mr-[10px] hover:cursor-pointer;
  }

  .hero-banner-bullets.hero-banner-bullets-active {
    @apply bg-brand-orange;
  }
}

/**
There's a small range of screen sizes where the text/buttons  
on the hero banner display poorly. This drops the spacing of the 
button and makes the text size smaller for this range.
**/
@media screen and (min-width: 768px) and (max-width: 864px) {
  .hero-banner__button:not(#\#):not(#\#){
    padding-left: var(--space-2xl);
    padding-right: var(--space-2xl);
  }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
  .hero-banner__title {
    font-size: 2em !important;
  }
}

