@layer components {
  .rectangle-list input[type=radio][data-disabled]+label, 
  .rectangle-list input[type=radio][disabled]+label {
   @apply bg-brand-light-grey;
  }

  .rectangle-list input[type=radio]:checked + label {
    @apply bg-brand-black text-brand-white;
  }

  [data-disabled]+.rectangle-list__option-value, 
  [disabled]+.rectangle-list__option-value {
    background: linear-gradient(to top left,transparent 0,transparent calc(50% - .8px),#00164d 50%,transparent calc(50% + .8px),transparent 100%);
  }
}