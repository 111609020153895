@layer components {
  .input-group {
    @apply flex flex-col gap-fluid-2xs;
  }

  .input-group .input-group__label {
    @apply m-0;
  }

  .input-group .input-group__input {
    @apply bg-brand-white text-brand-black py-fluid-xs pl-fluid-s placeholder:text-brand-black leading-[1] border-input rounded-input border-brand-black outline-0 ring-offset-2 focus:ring-2 ring-brand-orange hover:bg-brand-light-grey data-[error="true"]:border-brand-red data-[error="true"]:text-brand-red data-[error="true"]:border-2 disabled:placeholder:text-brand-dark-grey disabled:border-brand-dark-grey disabled:bg-brand-white w-full;
  }

  .input-group__search {
    @apply w-full h-full border-0 pr-[40px] wrapper-start paragraph-3 text-brand-darker-grey placeholder:text-brand-darker-grey;

    background-image: url("../img/search.svg");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
  }

  .input-group .input-group__select {
    @apply bg-brand-white text-brand-black py-fluid-xs pl-fluid-s placeholder:text-brand-black leading-[1] border-input rounded-input border-brand-black outline-0 ring-offset-2 focus:ring-2 ring-brand-orange hover:bg-brand-light-grey data-[error="true"]:border-brand-red data-[error="true"]:text-brand-red data-[error="true"]:border-2 disabled:placeholder:text-brand-dark-grey disabled:border-brand-dark-grey disabled:bg-brand-white;
  }

  .input-group .input-group__select[data-set-select] {
    @apply w-full;
  }

  .input-group .input-group__info {
    @apply paragraph-4 text-brand-purple data-[error="true"]:text-brand-red;
  }

  .input-group .input-group__input[data-error="true"] {
    background-image: url("../img/error.svg");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
  }
}
