:root {
  --swiper-scrollbar-border-radius: 10px;
  --swiper-scrollbar-top: auto;
  --swiper-scrollbar-bottom: 10px;
  --swiper-scrollbar-left: 50%;
  --swiper-scrollbar-right: auto;
  --swiper-scrollbar-sides-offset: 30%;
  --swiper-scrollbar-bg-color: #9D9D9D;
  --swiper-scrollbar-drag-bg-color: #EF5722;
  --swiper-scrollbar-size: 3px;
}

.swiper-pagination {
  display: flex;
  align-items: flex-end;
}

.swiper-button-disabled {
  opacity: 0;
}
