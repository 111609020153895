@layer components {
  .text-and-image {
    @apply grid grid-cols-1 gap-fluid-xl lg:grid-cols-2 overflow-hidden;
  }

  .text-and-image__text {
    @apply flex flex-col items-center justify-center p-wrapper-mobile md:p-wrapper-sm-tablet lg:p-wrapper-tablet xl:p-wrapper-xs-desktop 2xl:p-wrapper-sm-desktop 3xl:p-wrapper-desktop;

  }

  .text-and-image__text--title {
    @apply mb-fluid-xs;
  }

  .text-and-image__text--text {
    @apply max-w-xs;
  }

  .text-and-image__image {
    @apply w-full aspect-h-2 aspect-w-3;
  }

  .text-and-image__image.image-position-left {
    @apply order-1;
  }

  .text-and-image__image.image-position-right {
    @apply order-2;
  }

  .text-and-image__image.image-with-padding {
    @apply p-wrapper-mobile md:p-wrapper-sm-tablet lg:p-wrapper-tablet xl:p-wrapper-xs-desktop 2xl:p-wrapper-sm-desktop 3xl:p-wrapper-desktop;
  }
}
