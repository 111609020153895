@layer components {
  table {
    @apply w-[800px] md:w-full border border-brand-black border-collapse table-fixed mb-fluid-m;
  }

  table thead {
    @apply bg-brand-orange-tint;
  }

  table thead th,
  table thead td,
  table thead th p,
  table thead td p {
    @apply font-bold;
  }

  td {
    @apply p-1 text-center border-b border-brand-black;
  }

  td + td {
    @apply border-l border-brand-black
  }

  table td:first-child a {
    color: blue;
    text-decoration: underline;

    &:visited {
      color: purple;
    }

    &:hover {
      text-decoration: underline;
    }

  }
}

.table-wrapper {
  @apply overflow-auto mb-fluid-xl;
}

.table-wrapper td a {
  @apply text-brand-purple underline hover:underline visited:text-brand-purple;
}
