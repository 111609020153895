@layer components {
  .widget-text {
    @apply flex justify-center py-wrapper-mobile md:py-wrapper-sm-tablet lg:py-wrapper-tablet xl:py-wrapper-xs-desktop 2xl:py-wrapper-sm-desktop 3xl:py-wrapper-desktop;
  }
  
  .widget-text .widget-text-wrapper {
    @apply max-w-xl sm:text-center;
  }

  .widget-text .widget-text-title {
    @apply heading-3 pb-4;
  }

  .widget-text .widget-text-content {
    @apply paragraph-3;
  }
}
