@layer components {
  .text-and-video {
    @apply py-fluid-2xl;
  }

  .text-and-video__inner {
    @apply flex flex-col lg:flex-row lg:space-x-10;
  }

  .text-and-video .text-and-video__text-wrap {
    @apply md:flex md:flex-col md:justify-center w-full lg:w-1/2 mb-fluid-l;
  }

  .text-and-video .text-and-video__text-wrap h2 {
    @apply heading-3 uppercase mb-fluid-m;
  }

  .text-and-video .text-and-video__text-wrap p {
    @apply text-sm sm:paragraph-3;
  }

  .text-and-video .text-and-video__video-wrap {
    @apply w-full lg:w-1/2;
  }

  .text-and-video .text-and-video__video-wrap .text-and-video__video-wrap--inner {
    @apply relative pt-[56.25%];
  }

  .text-and-video .text-and-video__video {
    @apply absolute top-0 left-0 w-full h-full;
  }
}
