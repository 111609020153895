@layer components {
  .drawer {
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: fixed;

    transition: all 600ms cubic-bezier(.75,0,.175,1),visibility 600ms cubic-bezier(.75,0,.175,1);
    width: 90%;
    background: black;
    z-index: 60;
    will-change: auto;
  }

  @media screen and (min-width: 768px) {
    .drawer {
      width: 50%;
    }
  }

  @media screen and (min-width: 1200px) {
    .drawer {
      width: 40%;
    }
  }

  @media screen and (min-width: 1500px) {
    .drawer {
      width: 35%;
    }
  }

  .drawer[data-drawer-position="left"] {
    left: 0;
    transform: translateX(-101%);
  }

  .drawer[data-state="open"] {
    transform: translateX(0);
  }

  .drawer[data-drawer-position="right"] {
    will-change: auto;
    right: 0;
    transform: translateX(101%);
  }

  .drawer[data-drawer-position="right"][data-state="open"] {
    will-change: auto;
    transform: translateX(0);
  }

  .drawer[data-drawer-position="bottom"] {
    will-change: auto;
    bottom: 0;
    transform: translateY(101%);
  }

  .drawer[data-drawer-position="bottom"][data-state="open"] {
    will-change: auto;
    transform: translateY(0);
  }
}
