

/**
 * Layout
 * Custom utility classes for layout
 */

@layer utilities {
}

.page__title {
  @apply heading-3 text-center font-semibold italic uppercase mb-fluid-s;
}

.page__content {
  @apply lg:max-w-[1000px] lg:mx-auto md:py-fluid-4xl py-fluid-xl;

  & :is(h1, h2, h3) {
    @apply font-semibold italic uppercase mb-fluid-s;
  }

  & :is(h1, h2) {
    @apply heading-4;
  }

  & h3 {
    @apply heading-5;
  }

  & p {
    @apply !paragraph-3 mb-fluid-s;

    & span {
      @apply !paragraph-3;
    }
  }

  & p:has(strong):not(:first-child) {
    @apply mt-fluid-l;
  }

  & ul {
    @apply mb-fluid-s list-disc ml-8;
  }

  & a {
    @apply text-brand-purple underline hover:underline visited:text-brand-purple;
  }

  & .accordion__drawer-button span {
    @apply !paragraph-3;
  }
}
